body{
    all: unset;
}

@keyframes spin {
    from {
        transform:rotate(0deg);
    }
    to {
        transform:rotate(360deg);
    }
}

.spin {
    animation-name: spin;
    animation-duration: 1000ms;
    animation-iteration-count: infinite;
    animation-timing-function: linear; 
}

.searchItem{

}

.rounded-corners {
  border-radius: 25px 25px 0px 0px;
  box-shadow: 0px -8px 4px -6px rgba(185, 185, 185, 0.431);
}


@media screen and (min-width: 800px) {
  .rounded-borders {
    border-radius: 25px 25px 25px 25px ;
  }
}

.react-player {
  position: absolute;
  top: 0;
  left: 0;
}





::-webkit-scrollbar {
    display: none;
  }
  